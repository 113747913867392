import ConstructorIOClient from '@constructor-io/constructorio-client-javascript'
import { ref } from 'vue';

export function getProducts(podId, numProducts, podStrategy, itemId = null, filters = null) {
  const products = ref(null);
  const resultId = ref(null);

  let podNeedsItemId = ['alternative', 'complementary', 'bundles'];

  let options = {
    numResults: numProducts
  };

  let searchClientConfig = {
    apiKey: window.search.apiKey,
    fetch: fetch
  };

  if (window.cnstrcUserId) {
    searchClientConfig.userId = window.cnstrcUserId;
  } else {
    let mageCacheStorage = JSON.parse(localStorage.getItem('mage-cache-storage'));

    if (mageCacheStorage?.customer?.id) {
      searchClientConfig.userId = mageCacheStorage.customer.id;
    }
  }

  let searchClient = new ConstructorIOClient(searchClientConfig).recommendations;

  if (podNeedsItemId.includes(podStrategy)) {
    options.itemIds = itemId;
  }

  options.filters = JSON.parse(filters);

  options.variationsMap = {
    "group_by": [
      {
        "name": "color",
        "field": "data.color"
      },
    ],
    "values": {
      "image": {
        "aggregation": "first",
        "field": "data.image_url"
      }
    },
    "dtype": "object"
  };

  searchClient.getRecommendations(podId, options)
  .then(result => {
    products.value = result.response.results.map((p) => {
      // https://docs.constructor.io/integration/tracking/#recommendations
      let attrs = {};

      attrs['data-cnstrc-item'] = "recommendation";
      attrs['data-cnstrc-item-id'] = p.data.id;
      attrs['data-cnstrc-item-name'] = p.value;
      attrs['data-cnstrc-strategy-id'] = p.strategy.id;

      p.trackingAttrs = attrs;
      return p;
    });
    resultId.value = result.result_id;
  })
  .catch(() => { products.value = []; });

  return { products, resultId };
}
