<template>
  <template v-if="recommender_style === 'small'">
  <SmallRecommender
    :cloudinary_params="cloudinary_params"
    :display_name="display_name"
    :filters="filters"
    :item_id="item_id"
    :pod_id="pod_id"
    :pod_strategy="pod_strategy"
    :preferred_color="preferred_color"
    :product_card_components="product_card_components.split(',')"
    :product_image_style="product_image_style"
    :recommender_size="recommender_size"
  />
  </template>
  <template v-else>
    <Recommender
      :cloudinary_params="cloudinary_params"
      :collection="collection"
      :display_name="display_name"
      :filters="filters"
      :item_id="item_id"
      :pod_id="pod_id"
      :pod_strategy="pod_strategy"
      :preferred_color="preferred_color"
      :product_card_components="product_card_components.split(',')"
      :product_image_style="product_image_style"
      :recommender_size="recommender_size"
      :view_all_link="view_all_link"
    />
  </template>
</template>

<script>
import Recommender from './components/Recommender.vue'
import SmallRecommender from './components/SmallRecommender.vue';
import './global.css';

export default {
  name: 'App',
  components: {
    Recommender,
    SmallRecommender
  },
  props: [
    'cloudinary_params',
    'collection',
    'display_name',
    'filters',
    'pod_id',
    'pod_strategy',
    'item_id',
    'product_card_components',
    'product_image_style',
    'recommender_size',
    'recommender_style',
    'view_all_link',
  ],
  data() {
    return {
      preferred_color: '',
    }
  },
  methods: {
    recommenders: function() {
      return Object.values(window.search.recommender);
    },
  },
  created() {
    const cookieValue = document.cookie
      .split('; ')
      .find(row => row.startsWith('selected_swatch='))
      ?.split('=')[1];

    let itemId = this.item_id;

    if (!!itemId === false) {
      let hasSku = document.querySelector('#product_addtocart_form')?.dataset?.productSku;
      if (hasSku) {
        itemId = hasSku;
      }
    }

    if (cookieValue && itemId) {
      this.preferred_color = JSON.parse(decodeURIComponent(cookieValue))[itemId];
    }

    window.addEventListener('swatchChange', (e) => {
      this.preferred_color = e.detail.optionId;
    }, false);
  }
}
</script>

<style scoped>
*, *:before, *:after {
  box-sizing: border-box;
}
</style>
