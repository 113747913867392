<template>
  <div v-if="showSmallRecommender" class="small-recommender">
    <p v-if="display_name?.length" class="recommender-display-name">{{ display_name }}</p>
    <button class="recommender-close" @click="closeRecommender()" aria-label="Close Recommender"><CloseIcon /></button>
    <ul class="recommendations" v-bind="recommenderTrackingAttributesForRecommenderContainer">
      <li class="recommendation" v-for="product in products" :key="product.data.id" v-bind="product.trackingAttrs">
        <Recommendation
          :brand_collection="product.data.brand_collection"
          :cloudinary_params="cloudinary_params"
          :color="product.data.color"
          :image_url="product.data.image_url"
          :name="product.value"
          :preferred_color="product?.data?.swatch_options?.[206]?.[preferred_color]?.['child_ids']?.[0]"
          :preferred_color_label="product?.data?.swatch_options?.[206]?.[preferred_color]?.['label']"
          :price_range="product.data.price_range"
          :product_id="product.data.id"
          :product_image_style="product_image_style"
          :product_url="product.data.url"
          :rating="product.data.average_rating"
          :rating_count="product.data.review_count"
          :show_name="show_name()"
          :show_price_range="show_price_range()"
          :show_rating="show_rating(product.data.review_count)"
          :swatch_options="product.data.swatch_options"
          :variations="product.variations_map"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import Recommendation from './Recommendation.vue'
import CloseIcon from './icon/CloseIcon.vue'
import { computed } from 'vue';
import { getProducts } from '../products';

export default {
  name: 'SmallRecommender',
  components: {
    CloseIcon,
    Recommendation,
  },
  data() {
    return {
      showSmallRecommender: this.shouldShowSmallRecommender()
    }
  },
  props: [
    'cloudinary_params',
    'display_name',
    'filters',
    'item_id',
    'preferred_color',
    'pod_id',
    'pod_strategy',
    'product_card_components',
    'product_image_style',
    'recommender_size',
  ],
  computed: {
    recommenderTrackingAttributesForRecommenderContainer: function() {
      // https://docs.constructor.io/integration/tracking/#recommendations
      let attrs = {};

      attrs['data-cnstrc-recommendations'] = '';
      attrs['data-cnstrc-recommendations-pod-id'] = this.pod_id;
      attrs['data-cnstrc-result-id'] = this.resultId;
      attrs['data-cnstrc-num-results'] = this.numberOfProducts;

      return attrs;
    }
  },
  methods: {
    getProductsLength() {
      return this.products ? this.products.length : 0;
    },
    show_name: function() {
      return [].concat(this.product_card_components).includes('name')
    },
    show_price_range: function() {
      return [].concat(this.product_card_components).includes('price_range')
    },
    show_rating: function(count) {
      return ([].concat(this.product_card_components).includes('review_stars') && count !== undefined)
    },
    closeRecommender() {
      localStorage.setItem(this.pod_id + '-closed', Date.now());
      this.showSmallRecommender = false;
    },
    shouldShowSmallRecommender() {
      let timeClosed = parseInt(localStorage.getItem(this.pod_id + '-closed'));
      let weekOffset = (1000 * 60 * 60 * 24 * 7); // 7 Days

      if (isNaN(timeClosed)) {
        return true;
      }

      return timeClosed + weekOffset <= Date.now();
    }
  },
  setup(props) {
    const { products, resultId } = getProducts(
      props.pod_id,
      props.recommender_size,
      props.pod_strategy,
      props.item_id,
      props.filters
    );
    const numberOfProducts = computed(() => products.value?.length);

    return {
      products,
      resultId,
      numberOfProducts
    };
  },
}
</script>

<style scoped>
.small-recommender {
  background: #E9F3FB;
  border-radius: 5px;
  display: none;
  font-family: var(--font-family-base);
  padding: 10px 47px;
  position: relative;
}

.sliding-cart-modal .small-recommender {
  display: block;
}

.recommender-display-name {
  color: var(--color-black);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-normal);
  line-height: 1.2;
  text-align: center;
  margin: 0 0 9px;
}

.recommender-close {
  /* Undo UA Style */
  background: unset;
  border: unset;
  padding: unset;

  cursor: pointer;
  height: 44px;
  position: absolute;
  /* right: 9px; */
  /* top: 8px; */
  width: 44px;
  right: 0;
  top: 0;
}

.recommendations {
  /* Undo UA style */
  padding-left: 0;
  list-style: none;

  /* App style */
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 0;
  overflow-x: scroll;

  /* hide scrollbar but allow scrolling */
  scrollbar-width: none; /* for Gecko */
}

.recommendations::-webkit-scrollbar {
    display: none; /* for Blink/Webkit */
}

.recommendation {
  flex: 1 0 auto;
}

.recommendation :deep(img) {
  height: 75px;
  width: 55px;
}
</style>
