<template>
<div class="recommender" v-if="numberOfProducts > 0">
  <p v-if="display_name?.length" class="recommender-display-name">{{ display_name }}</p>
  <a v-if="view_all_link?.length" class="recommender-view-all" :href="viewAllLink(view_all_link)">View All</a>
  <div class="arrow-container">
    <button class="arrow left" v-show="showLeft" @click="leftButton()" aria-label="Scroll Left"><ScrollArrow /></button>
    <ul ref="recommendations" class="recommendations" @scroll="debounceScroll" v-bind="recommenderTrackingAttributesForRecommenderContainer">
      <li class="recommendation" v-for="product in products" :key="product.data.id" v-bind="product.trackingAttrs">
        <Recommendation
          :brand_collection="product.data.brand_collection"
          :cloudinary_params="cloudinary_params"
          :collection="collection"
          :color="product.data.color"
          :image_url="product.data.image_url"
          :name="product.value"
          :preferred_color="product?.data?.swatch_options?.[206]?.[preferred_color]?.['child_ids']?.[0]"
          :preferred_color_label="product?.data?.swatch_options?.[206]?.[preferred_color]?.['label']"
          :price_range="product.data.price_range"
          :special_price="product.data.special_price"
          :product_id="product.data.id"
          :product_image_style="product_image_style"
          :product_url="product.data.url"
          :rating="product.data.average_rating"
          :rating_count="product.data.review_count"
          :show_name="show_name()"
          :show_price_range="show_price_range()"
          :show_special_price="show_special_price()"
          :show_rating="show_rating(product.data.review_count)"
          :swatch_options="product.data.swatch_options"
          :variations="product.variations_map"
        />
      </li>
    </ul>
    <button class="arrow right" v-show="showRight" @click="rightButton()" aria-label="Scroll Right"><ScrollArrow /></button>
  </div>
</div>
</template>

<script>
import debounce from 'lodash/debounce';
import Recommendation from './Recommendation.vue'
import ScrollArrow from './icon/ScrollArrow.vue';
import { ref, computed } from 'vue';
import { getProducts } from '@/products';

export default {
  name: 'Recommender',
  components: {
    Recommendation,
    ScrollArrow,
  },
  data() {
    return {
      scrollLeft: 0,
      showLeft: false,
      showRight: true,
    }
  },
  props: [
    'cloudinary_params',
    'collection',
    'display_name',
    'filters',
    'item_id',
    'preferred_color',
    'pod_id',
    'pod_strategy',
    'product_card_components',
    'product_image_style',
    'recommender_size',
    'view_all_link',
  ],
  computed: {
    recommenderTrackingAttributesForRecommenderContainer: function() {
      // https://docs.constructor.io/integration/tracking/#recommendations
      let attrs = {};

      attrs['data-cnstrc-recommendations'] = '';
      attrs['data-cnstrc-recommendations-pod-id'] = this.pod_id;
      attrs['data-cnstrc-result-id'] = this.resultId;
      attrs['data-cnstrc-num-results'] = this.numberOfProducts;

      return attrs;
    }
  },
  methods: {
    slideWidth() {
      return this.$refs.recommendations.scrollWidth * .2;
    },
    getProductsLength() {
      return this.products ? this.products.length : 0;
    },
    show_name: function() {
      return [].concat(this.product_card_components).includes('name')
    },
    show_price_range: function() {
      return [].concat(this.product_card_components).includes('price_range')
    },
    show_special_price: function() {
      return [].concat(this.product_card_components).includes('special_price')
    },
    show_rating: function(count) {
      return ([].concat(this.product_card_components).includes('review_stars') && count !== undefined)
    },
    leftButton() {
      this.scrollLeft -= this.slideWidth() * this.slidesToScroll();

      if (this.scrollLeft < 0) {
        this.scrollLeft = 0;
      }

      this.scroll();
    },
    rightButton() {
      this.scrollLeft += this.slideWidth() * this.slidesToScroll();

      if (this.scrollLeft > this.maxScrollLeft()) {
        this.scrollLeft = this.maxScrollLeft();
      }

      this.scroll();
    },
    maxScrollLeft() {
      return this.$refs.recommendations.scrollWidth - this.$refs.recommendations.clientWidth;
    },
    slidesToScroll() {
      let width = this.$refs?.recommendations?.clientWidth || 0;

      return Math.floor(width / this.slideWidth());
    },
    shouldShowLeft() {
      if (this.scrollLeft > 0) {
        this.showLeft = true;
        return;
      }

      this.showLeft = false;
    },
    shouldShowRight() {
      // console.log('products', this.products.length, 'slides', this.slidesToScroll())
      if (this.products.length < this.slidesToScroll()) {
        this.showRight = false;
        return;
      }

      if (this.scrollLeft >= this.maxScrollLeft()) {
        this.showRight = false;
        return;
      }

      this.showRight = true;
    },
    handleScroll(event) {
      this.scrollLeft = event.target.scrollLeft;
    },
    scroll() {
      let recommendations = this.$refs.recommendations;

      recommendations.scrollTo({
          left: this.scrollLeft,
          behavior: 'smooth'
      });
    },
    viewAllLink(view_all_link) {
      let url = new URL(view_all_link, window.location.origin);
      return url.toString();
    }
  },
  setup(props) {
    const recommendations = ref(null);
    const { products, resultId } = getProducts(
      props.pod_id,
      props.recommender_size,
      props.pod_strategy,
      props.item_id,
      props.filters
    );
    const numberOfProducts = computed(() => products.value?.length);

    return {
      recommendations,
      products,
      resultId,
      numberOfProducts
    };
  },
  created() {
    this.debounceScroll = debounce(this.handleScroll, 300);
  },
  watch: {
    products() {
      this.$nextTick(() => {
        if (this.numberOfProducts > 0) {
          this.shouldShowLeft();
          this.shouldShowRight();
        }
      });
    },
    scrollLeft() {
      this.shouldShowLeft();
      this.shouldShowRight();
    }
  }
}
</script>

<style scoped>
.recommender {
  clear: both; /* PDP has floats that cause this to collapse in between */
  color: var(--color-black);
  display: grid;
  font-family: var(--font-family-base);
  font-weight: var(--font-weight-normal);
  grid-template-columns: 50% 50%;
  grid-template-rows: min-content 1fr;
  line-height: 1.5;
  margin: 40px auto;
  row-gap: 15px;
  max-width: 1600px;
}

.recommender-display-name {
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 1;

  font-size: var(--font-size-large);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  margin: 0;
}

@media only screen and (max-width: 1200px) {
  p.recommender-display-name {
    grid-column-end: 3;
    text-align: center;
  }
}

.recommender-view-all {
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  text-align: right;

  color: var(--color-dark-gray);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
  line-height: 1.2;
  text-decoration: underline;
  transition: text-decoration-color 0.3s ease-in-out;
}

.recommender-view-all:hover {
  text-decoration-color: transparent;
}

@media only screen and (max-width: 767px) {
  .recommender-view-all {
    grid-column-start: 1;
    grid-row-end: 3;
    grid-row-start: 3;
    text-align: center;
  }
}

.arrow-container {
  grid-column-end: 3;
  grid-column-start: 1;
  grid-row-end: 2;
  grid-row-start: 2;

  /**
   * This is require to prevent iOS Safari from stuttering on mobile. There is
   * a stutter in some instances when the CSS property filter is used on an
   * unrelated element above the recommender pod. The core issue is very
   * inconsistent and this was the only way that I found to solve it.
   */
  @media (min-width: 768px) {
    position: relative;
  }
}

.arrow {
  /* Undo UA Styles */
  border: unset;

  /* App Styles */
  height: 44px;
  top: 35%;
  width: 44px;
  position: absolute;
  background: #fff;
}

.arrow.left {
  left: 2vw;
  z-index: 1;
  transform: scaleX(-1);
}

.arrow.right {
  right: 2vw;
}

.arrow:hover {
  /* Undo Mage Styles */
  color: unset;

  /* App Styles */
  cursor: pointer;
}

.arrow :deep(svg path) {
  fill: var(--color-black);
  transition: fill 0.3s ease-in-out;
}

.arrow:hover :deep(svg path) {
  fill: var(--brand-primary);
}

@media only screen and (max-width: 767px) {
  .arrow {
    display: none;
  }
}

.recommendations {
  display: flex;
  flex-direction: row;
  gap: 20px;
  list-style: none;
  margin: 0;
  overflow-x: scroll;
  padding: 0;

  /* hide scrollbar but allow scrolling */
  scrollbar-width: none; /* for Gecko */
}

@media only screen and (max-width: 767px) {
  ul.recommendations {
    padding-inline: 15px;
  }

  .catalog-product-view ul.recommendations {
    margin-inline: -15px;
  }
}

.recommendations::-webkit-scrollbar {
    display: none; /* for Blink/Webkit */
}

@media only screen and (max-width: 767px) {
  .recommendation {
    flex: 1 0 40%;
  }
}

@media only screen and (min-width: 768px) {
  .recommendation {
    flex: 0 0 calc(22.5% - 20px);
  }
}
</style>
