<template>
<div class="recommendation" @click="setSwatchCookie()">
  <a :href="product_url" :class="{loading: loading}">
    <span class="loader"></span>
    <div v-if="showPromoFlag" class="promo-flag">✓ Perfect Match</div>
    <img
      :alt="show_name ? '' : name"
      :class="{circle: product_image_style === 'circle'}"
      :src="modifyCloudinaryParams(imgsrc)"
      loading="lazy"
      width="179"
      height="250"
      ref="image"
    />
    <p v-if="show_name">
      <span class="item-collection">{{ brand_collection }}</span>
      <span class="item-name">{{ name }}</span>
    </p>
    <p class="price" v-if="show_price_range">{{ price_range }}</p>
    <p class="sale-price" v-if="show_special_price">Sale from <span class="lowest-price">${{ special_price }}</span></p>
  </a>
  <a v-if="show_rating" :href="product_url + '#tab-label-product.view.reviews'">
    <div class="rating">
      <div class="star-rating" :aria-label="rating + ' Stars'">
        <div class="fill-ratings" :style="{ width: (rating / 5 * 100) + '%' }">
          <span></span>
        </div>
        <div class="empty-ratings">
          <span></span>
        </div>
      </div>
      <span v-if="rating_count" :href="product_url + '#tab-label-product.view.reviews'" class="rating-count">({{ rating_count}})</span>
    </div>
  </a>
</div>
</template>

<script>
export default {
  name: 'Recommendation',
  props: [
    'brand_collection',
    'cloudinary_params',
    'collection',
    'color',
    'image_url',
    'name',
    'preferred_color',
    'preferred_color_label',
    'price_range',
    'special_price',
    'product_id',
    'product_image_style',
    'product_url',
    'rating',
    'rating_count',
    'show_name',
    'show_price_range',
    'show_special_price',
    'show_rating',
    'swatch_options',
    'variations'
  ],
  data() {
    return {
      loading: false,
      swatches: {}
    }
  },
  methods: {
    modifyCloudinaryParams(src) {
      let url = new URL(src);
      let pathname = url.pathname.split('/');
      let dpr;

      if (!url.origin.includes('cloudinary')) {
        // Placeholder
        return 'https://res.cloudinary.com/scrubsandbeyond/image/upload/c_pad,e_bgremoval,f_auto,h_500,q_auto:best,w_400/b_rgb:f3f3f3/search/placeholder.jpg';
      }

      if (window.devicePixelRatio) {
        dpr = 'dpr_' + window.devicePixelRatio;
      }

      url.pathname = [
        pathname[1],
        pathname[2],
        pathname[3],
        dpr,
        this.cloudinary_params,
        pathname[pathname.length - 1]
      ].join('/');

      return url.toString();
    },
    setSwatchCookie() {
      let selectedSwatch = {};
      let swatchData;

      // Gift Cards do not have swatch_options and therefore no cookie can be
      // set.  This check safeguards against:
      // Cannot read properties of undefined (reading 206)
      // M2PL-5341
      if (!this.swatch_options) {
        return;
      }

      if (this.preferred_color) {
        swatchData = Object.values(this.swatch_options[206])
          .filter((so) => {
            return so.child_ids[0] === this.preferred_color
          })
          .pop();
      } else {
        swatchData = Object.values(this.swatch_options[206])
          .filter((so) => {
            return so.label === this.color
          })
          .pop();
      }

      selectedSwatch[this.product_id] = swatchData.option_id;

      document.cookie = 'selected_swatch=' + encodeURIComponent(JSON.stringify(selectedSwatch)) + ";path=/";
    },
  },
  computed: {
    imgsrc() {
      return this.variations?.[this.preferred_color_label]?.image || this.image_url;
    },
    showPromoFlag() {
      let show = false;
      let cleanedCollection = this.collection?.replace('\'', '');

      if (this.preferred_color && (cleanedCollection === this.brand_collection)) {
        show = true;
      }

      return show;
    }
  },
  watch: {
    imgsrc: {
      handler() {
        // First run will not have an image ref yet; just bail
        if (!this.$refs.image) {
          return;
        }

        this.loading = true;
        this.$refs.image.addEventListener('load', () => {
          this.loading = false;
        }, {once: true});
      },
      immediate: true
    }
  }
}
</script>

<style scoped>
.recommendation {
  position: relative;
  min-width: 157px; /* Minimum for promo-flag */
}

.recommendation a {
  color: var(--color-black);
  text-decoration: none;
}

.loader {
  display: none;
}

.loading .loader,
.loading .loader:after {
  border-radius: 50%;
  width: 45px;
  height: 45px;
}

.loading .loader {
  display: block;
  margin: 0 auto;
  font-size: 5px;
  text-indent: -9999em;
  border-top: 4px solid rgba(185, 185, 185, 0.6);
  border-right: 4px solid rgba(185, 185, 185, 0.6);
  border-bottom: 4px solid rgba(185, 185, 185, 0.6);
  border-left: 4px solid var(--brand-primary);
  transform: translateZ(0);
  animation: load8 1.1s infinite linear;
  z-index: 2;
  position: absolute;
  left: 0;
  right: 0;
  top: 18vh;
}
@-webkit-keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.promo-flag {
  align-items: center;
  background-color: var(--brand-primary);
  border-radius: 30px;
  color: var(--color-white);
  display: flex;
  font-size: var(--font-size-small);
  height: 30px;
  left: 10px;
  padding: 0px 15px;
  position: absolute;
  top: 10px;
}

img {
  aspect-ratio: 5/7;
  display: block;
  margin: 0 auto 11px;
  max-width: 100%;
  object-fit: cover;
  width: 100%;
  height: auto;
}

img.circle {
  aspect-ratio: 1;
  border-radius: 50%;
  object-fit: cover;
  width: 200px; /* Required for Gecko */
}

p {
  margin: 0;
  font-size: var(--font-size-medium);
}

p:hover {
  text-decoration: underline;
}

.item-collection {
  display: block;
  margin-bottom: 4px;
}
.item-name {
  display: block;
  font-size: 13px;
}

.rating {
  display: flex;
  align-items: center;
  gap: 5px;
}

.price {
  margin-top: 16px;
}

.price,
.sale-price {
  font-size: 12px;
}

.recommender .sale-price {
  color: var(--color-sale);
}

.star-rating {
  font-size: calc(var(--font-size-large) + 4px);
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
  unicode-bidi: bidi-override;
  width: max-content;
}

.star-rating .fill-ratings {
  display: block;
  left: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  top: 0;
  z-index: 1;
}

.star-rating span {
  display: inline-block;
  letter-spacing: -1.25px;
  width: max-content;
  --rating-color-gray: #ccc;
}

.star-rating span:before {
  color: var(--rating-color-gray);
  content: "★ ★ ★ ★ ★";
}

.fill-ratings span:before {
  color: var(--brand-primary);
}

.star-rating .empty-ratings {
  display: block;
  padding: 0;
  z-index: 0;
}

.rating-count {
  text-decoration: none;
  font-size: var(--font-size-small);
  color: var(--brand-primary)
}
</style>
